$colors: (
    "white": #FAFAFF, 
    "black": #000000, 
    "dark-grey": #252525,
    "light-grey": #979797,
    "primary": #2E7D32, 
    "primary-light": #66BB6A, 
    "accent": #EF6C00, 
    "accent-light": #FFA726
);

@each $color-name, $color-value in $colors {
    .#{$color-name}-background {
        background-color: $color-value;

        @if $color-name == "white" {
            box-shadow: 0 0 0 1px white;
        }
    }

    .#{$color-name}-text {
        color: $color-value;
    }
}

.navlink-background {
    background-color: map-get($colors, "primary");
    transition: 0.2s;

    &:hover {
        background-color: map-get($colors, "primary-light");
    }
}

.navlink-active-background {
    background-color: map-get($colors, "primary-light");
}