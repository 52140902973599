@import 'Color.scss';

body {
    margin: 0px;
}

ol, ul, dl {
    margin-bottom: 0;
}

hr, p {
    margin: 0;
}

#root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    background-color: map-get($colors, "white");;
}

.cursor-pointer {
    cursor: pointer;
}

.animationPreload {
    animation-duration: 0s !important;
}