@import 'Color.scss';

$header-font: 'Arial', Arial, sans-serif;
$base-font: 'Arial', Arial, sans-serif;
$base-font-size: 10px;
$base-font-calculation: calc($base-font-size + 0.75vh);

* {
    font-family: $base-font;
    font-size: $base-font-calculation;
}

@for $i from 1 through 5 {
    h#{6 - $i} {
        font-family: $header-font;
        font-size: calc($base-font-size + ($i * 6px) + 0.75vh);
        margin: 0px;
    }

    .text-size-#{$i} {
        font-size: calc(($base-font-size - ($i * 1px)) + 0.75vh);
    }
}

span, p, input {
    &:last-of-type {
        margin-bottom: 0;
    }
}

@each $color-name, $color-value in $colors {
    .#{$color-name}-text {
        color: $color-value;
    }
}

.link-text {
    color: map-get($colors, "black");
    text-decoration: none;

    &:visited, &:hover {
        color: map-get($colors, "black") !important;
    }
}

.sub-text {
    color: map-get($colors, "grey");
}