@import 'Color.scss';
@import 'Sides.scss';

@each $color-name, $color-value in $colors {
    @each $side in $sides {
        @if $side != all {
            .#{$color-name}-#{$side}-border-thin {
                border-#{$side}: 1px solid $color-value;
            }

            .#{$color-name}-#{$side}-border {
                border-#{$side}: 2px solid $color-value;
            }

            .#{$color-name}-#{$side}-border-strong {
                border-#{$side}: 4px solid $color-value;
            }
        }
        @else {
            .#{$color-name}-border {
                border: 2px solid $color-value;
            }

            .#{$color-name}-border-strong {
                border: 4px solid $color-value;
            }
        }
    }
}