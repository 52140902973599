$media-max-width: 900px;
$media-max-height: 600px;

@for $i from 1 through 100 {
    .width-#{$i} {
        width: #{$i} + '%';

        @media (max-width: $media-max-width) {
            width: 100%;
        }
    }

    .height-#{$i} {
        height: #{$i}vh;

        @media (max-height: $media-max-height) {
            height: auto;
        }
    }
}