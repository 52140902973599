@import 'Sides.scss';

@function spaceAmount($n) {
    @return $n * 5;
}

@for $i from 0 through 20 {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            @if $side != all {
                margin-#{$side}: #{spaceAmount($i)}px;
            }
            @else {
                margin: #{spaceAmount($i)}px;
            }
        }

        .p#{str-slice($side, 0, 1)}-#{$i} {
            @if $side != all {
                padding-#{$side}: #{spaceAmount($i)}px;
            }
            @else {
                padding: #{spaceAmount($i)}px;
            }
        }
    }

    .gap-#{$i} {
        gap: #{spaceAmount($i)}px !important;
    }
}