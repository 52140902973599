.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.item-align-center {
    align-items: center;
}

.item-align-top {
    align-items: flex-start;
}

.item-align-bottom {
    align-items: flex-end;
}

.item-align-end {
    align-items: end;
}

.text-align-start {
    text-align: start !important;
}

.text-align-center {
    text-align: center !important;
}

.text-align-end {
    text-align: end !important;
}