@import 'Color.scss';
@import 'Shadow.scss';

.basic-line {
    border-top: none;
    padding: 2px 0 2px 0;
    opacity: 1;
    box-shadow: $shadow-light;
}

.accent-line {
    border: 2px solid map-get($colors, "accent");
    opacity: 1;
}