@import 'Shadow.scss';
@import 'Color.scss';

.basic-box {
    background-color: map-get($colors, "white");
    padding: 10px;
    box-shadow: $shadow-basic;
}

.small-box, .circle-box {
    background-color: map-get($colors, "white");
    float: left;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 3px;
    width: 20px;
    box-shadow: 0 0 0 1px map-get($colors, "white");
}

.circle-box {
    border-radius: 50%;
}