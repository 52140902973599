@import 'Sizing.scss';

.flex, .flex-vertical, .flex-absolute {
    display: flex;
}

.flex {
    @media (max-width: $media-max-width) {
        flex-direction: column;
    }
}

.flex-vertical {
    flex-direction: column;
}

@for $i from 1 through 100 {
    .flex-#{$i} {
        flex: #{$i} + '%';

        @media (max-width: $media-max-width) {
            flex: auto;
        }
    }
}

.flex-fill {
    flex: 1;
}